export const METADATA = {
  route: '/contact-us',
  imagePath: 'social-contact.jpg',
  pageType: 'website',
  title: 'Contact us',
  description: `
    Get more information about RMA Group Life Cover
    queries, claims and complaints.
  `,
}
