import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Background from '../../components/Background'
import { DESKTOP, TABLET } from '../../constants/breakpoints'
import { LIGHT, LIGHTEST } from '../../constants/colors'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  max-width: ${DESKTOP}px;
  margin: 0 auto;

  @media screen and (max-width: ${TABLET}px) {
    display: block;
    padding: 1.5rem 2rem;
    padding-bottom: 3rem;
  }
`

const Body = styled.section`
  width: 60%;
  padding-top: 5rem;
  padding-right: 5rem;
  padding-left: 10rem;

  @media screen and (max-width: ${TABLET}px) {
    width: 100%;
    padding: 0;
  }
`

const Sidebar = styled.aside`
  width: 40%;
  padding-right: 5rem;
  padding-top: 5rem;
  padding-left: 5rem;
  border-left: solid 1px ${LIGHT};

  @media screen and (max-width: ${TABLET}px) {
    width: 100%;
    padding: 0;
    border: none;
  }
`

const Component = ({ sidebar, children }) => (
  <Background
    color={LIGHTEST}
    filename="pattern.png"
    size="80%"
    position="2.5rem 7.5rem"
    repeat="repeat"
  >
    <Container>
      <Body>{children}</Body>
      <Sidebar>{sidebar}</Sidebar>
    </Container>
  </Background>
)

Component.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.node.isRequired,
}

export default Component
