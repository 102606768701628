import React from 'react'
import styled from 'styled-components'
import Layout from '../../components/Layout'
import Accordion from '../../components/Accordion'
import AccordionPanel from '../../components/AccordionPanel'
import SideBySide from '../../components/SideBySide'
import LeadForm from '../../components/Form/lead'
import { TABLET } from '../../constants/breakpoints'
import { GROUP_URL_BASE, POSTAL_URL_BASE } from '../../../env'
import { METADATA } from '../../config/contactUs'
import Anchor from '../../components/Anchor'

const Heading = styled.h1`
  font-size: 1.5rem !important;
  line-height: 180% !important;
  text-transform: uppercase;

  @media screen and (max-width: ${TABLET}px) {
    font-size: 1.25rem !important;
    text-align: center;
  }
`

const Sidekick = () => (
  <div>
    <Heading>Let us call you</Heading>
    <LeadForm postalUrl={POSTAL_URL_BASE} type="inline" />
  </div>
)

const Page = () => (
  <Layout {...METADATA}>
    <SideBySide sidebar={<Sidekick />}>
      <Heading>Contact Us</Heading>
      <Accordion>
        <AccordionPanel title="Office hours">
          <p>
            We operate on Mondays to Fridays from 8am to 5pm and are standing by
            to support you.
          </p>
        </AccordionPanel>
        <AccordionPanel title="Queries">
          <p>
            If you’re already a customer, email us at{' '}
            <Anchor href="mailto:groupadmin@randmutual.co.za">
              groupadmin@randmutual.co.za
            </Anchor>{' '}
            or call us on <Anchor href="tel:+27210451469">021 045 1469</Anchor>.
          </p>
          <p>
            If you’re interested in signing up, complete a{' '}
            <Anchor href={GROUP_URL_BASE}> quote</Anchor> now, call us on{' '}
            <Anchor href="tel:+27210451448">021 045 1448</Anchor> or have us{' '}
            <Anchor href="/call-me">give you a call</Anchor>.
          </p>
        </AccordionPanel>
        <AccordionPanel title="Claims">
          <p>
            Make sure your loved ones know that you have taken out this policy,
            and how to contact us if they ever need to claim. We are in this
            business to pay benefits to those in need, and we are standing by to
            help.
          </p>
          <p>
            If you need more information or forms to fill in then check out our{' '}
            <Anchor href="/how-to-claim">how to claim</Anchor> page.
          </p>
          <p>
            You can also call us on{' '}
            <Anchor href="tel:+27210451469">021 045 1469</Anchor> or email us at{' '}
            <Anchor href="mailto:claims@randmutual.co.za">
              claims@randmutual.co.za
            </Anchor>
            .
          </p>
        </AccordionPanel>
        <AccordionPanel title="Complaints">
          <p>
            Should you have any complaints about the service RMA Life has
            provided, please contact us immediately at{' '}
            <Anchor href="mailto:complaints@randmutual.co.za">
              complaints@randmutual.co.za
            </Anchor>{' '}
            so that we can attempt to resolve your problem or complaint. We are
            committed to helping you in every way we can.
          </p>
        </AccordionPanel>
        <AccordionPanel title="Compliance Officer">
          <p>
            If, after contacting us, you still feel your complaint is
            unresolved, the matter can be pursued with the Ombudsman for
            Long-term Insurance. If you feel that RMA Life has contravened the
            provisions of FAIS, please contact the RMA Life Compliance Officer,
            or the FAIS Ombud.
          </p>
          <ul>
            <li>
              Compliance officer:{' '}
              <Anchor href="mailto:blourens@randmutual.co.za">
                blourens@randmutual.co.za
              </Anchor>
            </li>
            <li>
              Ombudsman for Long-term Insurance:{' '}
              <Anchor href="mailto:info@ombud.co.za">info@ombud.co.za</Anchor>
            </li>
            <li>
              FAIS Ombud:{' '}
              <Anchor href="mailto:info@faisombud.co.za">
                info@faisombud.co.za
              </Anchor>
            </li>
          </ul>
        </AccordionPanel>
        <AccordionPanel title="Head Office">
          <p>
            Our head offices reside in{' '}
            <Anchor href="https://goo.gl/maps/bMWps1pN7N4yTM7s6">
              10 St Andrews Road, Parktown, 2193
            </Anchor>
            .
          </p>
        </AccordionPanel>
      </Accordion>
    </SideBySide>
  </Layout>
)

export default Page
